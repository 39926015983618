import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_MOBILE_APP_DOWNLOAD_BOTTOM_BANNER_ENABLED = defineFcm('mobile_app_download_bottom_banner_enabled')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				traffic_percentage: { type: 'number' },
			},
		}),
	)
	.withDefault({
		traffic_percentage: 0,
	})
	.toConfig();
